<template>
  <div class="productList">
    <search-com  @search-btn="searchBtn"></search-com>
    <div class="nav_box">
      <welfare-breadcrumb :itemBreadcrumb="itemBreadcrumb"></welfare-breadcrumb>
      <div class="search_type">
        <span>分类：</span>
        <div class="type_list">
          <div :class="searchType == 0 ?'type_item item_active':'type_item'" @click="chooseProductType(0,'全部')">全部</div>
          <div :class="searchType == productTypeItem.id ?'type_item item_active':'type_item'" 
            v-for="productTypeItem,index in productList" :key="index"
            @click="chooseProductType(productTypeItem.id,productTypeItem.name)">
            {{productTypeItem.name}}
          </div>
        </div>
      </div>
    </div>
    <!-- 商品列表 -->
    <div class="product_main">
      <div class="product_list" v-if="shopProductList.length > 0">
        <div class="product_item_box" v-for="item in shopProductList" :key="item.commodityID" @click="getDetails(item)">
          <el-image
            v-if="item.commodityPictures"
            class="product_pictures"
            :src="item.commodityPictures"
            fit="fill">
          </el-image>
          <div class="product_name">{{item.goodName}}</div>
          <div class="product_information">
            <span class="product_price">￥{{item.enterprisePrice}}</span>
            <span class="product_details" @click="getDetails(item)">查看详情</span>
          </div>
        </div>
      </div>
      <div class="task-list-page" v-if="shopProductList.length > 0">
          <el-pagination
              @current-change="OnCurrentPageChanged"
              @size-change="handleSizeChange"
              :current-page="Params.PageIndex"
              :page-sizes="[20, 30, 40, 50, 60]"
              layout="prev, pager, next,sizes,jumper,total"
              :page-size="Params.PageSize"
              background
              :total="Params.TotalCount"
          >
          </el-pagination>
      </div>
      <section class="con-body" v-else>
      <div class="shopping_car_empty">
		  <img class="img" src="~/assets/img/welfareMall/search_bg.png" alt="">
        <div></div>
      </div>
    </section>
    </div>
  </div>

</template>

<script>
import GoodListCom from '@/components/welfareMallComp/GoodListCom'
import SearchCom from '@/components/welfareMallComp/SearchCom';
import WelfareBreadcrumb from '@/components/welfareMallComp/WelfareBreadcrumb'
import { getProductList,getProductListCommodity } from "@/network/welfareMall_api";
export default {
  name: 'productList',
  components: {
    GoodListCom,
    WelfareBreadcrumb,
    SearchCom
  },
  data() {
    return {
      itemBreadcrumb: [
        {
          name: '福利商城',
          to: '/welfareHome'
        },
        {
          name: '',
        }
      ],
      shopProductList:[],
      productList:[],// 类别
      searchType:this.$route.params.id,
      Params: {
        PageIndex: 1,
        PageSize: 20,
        TotalCount: 5,
        TotalPage: 0,
      },
    };
  },
  computed:{
  },
  watch:{
     "searchType":{
        handler(val){
          //console.log(val)
          this.searchType = val;
          sessionStorage.setItem('productList_categoryId', val);
        },
        deep: true,
    }
  },
  created() {
    //console.log(this.$route.params)
    this.getProductTypeList();
  },
  methods: {
    //获取商品分类
    async getProductTypeList(){
      let _this = this;
      //默认条数 100
      let res = await getProductList(100);
      if(res.isSuccess){
        _this.productList = res.data;
        for(let key in _this.productList){
            if(_this.searchType == _this.productList[key].id){
              _this.itemBreadcrumb[1].name = _this.productList[key].name;
              break
            }
        }
        if(_this.searchType == ''||_this.searchType == undefined){
          _this.searchType = 0;
        }
        if(_this.$route.params.id){
          _this.searchType = _this.$route.params.id;
        }else if(sessionStorage.getItem('productList_commodityID')){
          // 页面点刷新走这一步
          _this.searchType = sessionStorage.getItem('productList_categoryId');
          //console.log(this.searchType)
        }
        _this.itemBreadcrumb[1].name = sessionStorage.getItem('productList_categoryName')
        _this.getShopProductList(_this.searchType,1,20);
      }
    },
    //获取商品分类下的商品
    async getShopProductList(id,pageIndex,limit){
        let resList = await getProductListCommodity({
            categoryId: id,
            pageIndex,
            limit
        });
        if(resList.isSuccess){
            this.shopProductList =  resList.data
            this.Params.TotalCount = resList.count;
            this.Params.TotalPage = resList.count;
        }
    },
    //获取分页
    OnCurrentPageChanged(val) {
      this.Params.PageIndex = val;
      this.getShopProductList(this.searchType,this.Params.PageIndex,this.Params.PageSize)
    },
    handleSizeChange(val) {
      this.Params.PageSize = val;
      this.getShopProductList(this.searchType,this.Params.PageIndex,this.Params.PageSize)
    },
    // 点击搜索
    searchBtn(val){
      if(!val){
        return this.$message.info('请输入礼包名称');
      }
      document.cookie = "vueRouter="+ '/welfareSearchResult';
      this.$router.push({
        name: 'WelfareSearchResult',
        params: {
          value: val
        }
      })
    },
    //选择分类
    chooseProductType(typeId,typeName){
      this.searchType = typeId;
      this.itemBreadcrumb[1].name = typeName;
      sessionStorage.setItem('productList_categoryId', typeId);
      sessionStorage.setItem('productList_categoryName', typeName);
      this.getShopProductList(this.searchType,1,20);
    },
    //  跳转详情
    getDetails(item) {
      const { commodityID} = item;
      //console.log(categoryId,categoryName,commodityID)
      //缓存当前ID 刷新用到
      sessionStorage.setItem('productList_commodityID', commodityID);
      document.cookie = "vueRouter="+ '/productDetails';
      this.$router.push({
        name: 'productDetails',
        params: {commodityID}
      })
    },
  },
};
</script>

<style scoped lang="less">
.productList{
  //width: 1200px;
  margin: 0 auto;
  padding-bottom: 60px;
  .nav_box{
    width:1200px;
    margin:24px auto;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px 8px 24px 1px rgba(0,56,145,0.0600);
    border-radius: 16px 16px 16px 16px;
    padding:24px;
    padding-bottom: 8px;
    .search_type{
      margin-top: 30px;
      span{
        float: left;
        width: 42px;
        height: 19px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        margin-top: 7px;
      }
      .type_list{
        margin-left: 58px;
        .type_item{
          display: inline-block;
          margin-right: 40px;
          padding:6px 12px;
          margin-bottom: 16px;
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          border-radius: 16px 16px 16px 16px;
        }
        .type_item:hover{
          cursor: pointer;
          background: #59B0EC;
          color:#fff;
        }
        .item_active{
          background: #0089CD;
          color: #fff;
        }
      }
    }
  }
  .product_main{
    width:1200px;
    margin:0 auto;
    .product_item_box{
      display: inline-block;
      width: 282px;
      height: 365px;
      background: #FFFFFF;
      border-radius: 0px 0px 0px 0px;
      border: 1px solid #E0E0E0;
      padding:12px;
      margin-right: 24px;
      margin-bottom: 25px;
      cursor: pointer;
      .product_pictures{
        width: 258px;
        height: 258px;
      }
      .product_name{
        margin-top: 12px;
        font-size: 16px;
        font-weight: 400;
        color: #3C3C3C;
        line-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .product_information{
        margin-top: 14px;
        height: 28px;
        line-height: 28px;
        .product_price{
          font-size: 18px;
          font-weight: 400;
          color: #F5222D;
        }
        .product_details{
          float: right;
          font-size: 14px;
          font-weight: 400;
          color: #999999;
        }
      }
    }
    .product_item_box:nth-child(4n){
      margin-right: 0;
    }
    .task-list-page{
      text-align: center;
    }
    .con-body{
      text-align: center;
    }
  }
}
</style>
